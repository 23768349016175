import { connect } from "react-redux";
import { Fragment, useEffect, useState } from "react";
import Input from "src/components/Shared/Forms/Inputs/Input";
import Modal from "src/components/Shared/Modal";
import { LinkIcon, ShieldCheckIcon } from "@heroicons/react/24/outline";
import { workspaceIntegrationCredentialCreate, workspaceIntegrationCredentialManage } from "src/actions/workspace";
import { toast } from "react-toastify";
import { replacePrivateStringWithStars } from "src/helpers/replacePrivateStringWithStars";
import { apiRequest } from "src/async/apiUtils";
import InputWrapper from "src/components/Shared/Forms/InputWarpper";

const FieldItem = ({ field = null, editItem = null, getValue = () => {}, getPlaceholder = () => {}, onChange = () => {}, onRemove = () => {} }) => {
  return (
    <div className="relative">
      {!field?.required ? (
        <InputWrapper
          inline={true}
          type={field?.type}
          name={field?.name}
          label={field?.label}
          placeholder={getPlaceholder(field?.name)}
          autoComplete={`new-password`}
          value={getValue(field?.name)}
          onChange={onChange}
          onRemove={() => onRemove(field?.name)}
        />
      ) : (
        <Input
          inline={true}
          type={field?.type}
          name={field?.name}
          label={field?.label}
          placeholder={getPlaceholder(field?.name)}
          autoComplete={`new-password`}
          value={getValue(field?.name)}
          onChange={onChange}
          onRemove={() => onRemove(field?.name)}
        />
      )}
    </div>
  );
};

const WorkspacesIntegrationModal = ({
  isOpen,
  workspaceId = null,
  is_global = false,
  providerDetails = null,
  embedType = null,
  editItem = null,
  integrationList = [],
  onCancel = () => {},
  onSuccess = () => {},
  setIntegrationList = () => {},
  setIsPreloading = () => {},
  workspaceDetails,
  ...props
}) => {
  const [disabled, setDisabled] = useState(false);
  const [embedAttributes, setEmbedAttributes] = useState([]);

  const manageIntegration = async () => {
    try {
      setDisabled(true);
      if (editItem?._id) {
        await props.workspaceIntegrationCredentialManage({ integration_id: providerDetails._id, workspace_id: workspaceId, credential_id: editItem?._id, embed_attributes: embedAttributes });
      } else {
        const data = await props.workspaceIntegrationCredentialCreate({ integration_id: providerDetails._id, workspace_id: workspaceId, embed_attributes: embedAttributes, integration_type: embedType?.type });
        if (integrationList?.length === 0) setIsPreloading(true);
        setIntegrationList(data);
      }

      if (workspaceDetails?.workspace_type === "JWT_FULL_EMBED") {
        try {
          apiRequest("post", `/workspaces/${workspaceId}/domo-data-count`, { body: {} });
        } catch (err) {}
      }

      onSuccess();
      setTimeout(() => setDisabled(false), 300);
    } catch (error) {
      toast.error(error.message);
      setTimeout(() => setDisabled(false), 300);
    }
  };

  const onRemove = (name) => {
    setEmbedAttributes((preData) => [
      ...preData.map((data) => {
        if (data.key === name) {
          return {
            ...data,
            value: "",
            placeholder: "",
            isHide: true,
          };
        } else {
          return data;
        }
      }),
    ]);
  };

  const onChange = (event) => {
    setEmbedAttributes((preData) => [
      ...preData.map((data) => {
        if (data.key === event.target.name) {
          return {
            ...data,
            value: event.target.value,
            isHide: false,
          };
        } else {
          return data;
        }
      }),
    ]);
  };

  const getValue = (name) => {
    return embedAttributes.find((embedAttribute) => embedAttribute.key === name)?.value;
  };

  const getPlaceholder = (name) => {
    return embedAttributes.find((embedAttribute) => embedAttribute.key === name)?.placeholder;
  };

  useEffect(() => {
    if (embedType?.embed_fields && embedType?.embed_fields?.length > 0) {
      let embedAttributesField = [];
      for (const field of embedType.embed_fields) {
        if (editItem) {
          if (editItem?.workspace_id) {
            if (field?.used_for === "both" || field?.used_for === "workspace") {
              embedAttributesField.push({
                key: field?.name,
                type: field?.type,
                value: field?.type === "text" ? editItem?.embed_attributes?.find((embed_attribute) => embed_attribute.key === field?.name)?.value : "",
                placeholder: field?.type === "password" ? replacePrivateStringWithStars(editItem?.embed_attributes?.find((embed_attribute) => embed_attribute.key === field?.name)?.value) : "",
              });
            }
          } else {
            if (field?.used_for === "both" || field?.used_for === "global") {
              embedAttributesField.push({
                key: field?.name,
                type: field?.type,
                value: field?.type === "text" ? editItem?.embed_attributes?.find((embed_attribute) => embed_attribute.key === field?.name)?.value : "",
                placeholder: field?.type === "password" ? replacePrivateStringWithStars(editItem?.embed_attributes?.find((embed_attribute) => embed_attribute.key === field?.name)?.value) : "",
              });
            }
          }
        } else {
          if (workspaceId) {
            if (field?.used_for === "both" || field?.used_for === "workspace") {
              embedAttributesField.push({
                key: field?.name,
                type: field?.type,
                value: field?.default_value || "",
                placeholder: "",
              });
            }
          } else {
            if (field?.used_for === "both" || field?.used_for === "global") {
              embedAttributesField.push({
                key: field?.name,
                type: field?.type,
                value: field?.default_value || "",
                placeholder: "",
              });
            }
          }
        }
      }
      setEmbedAttributes(embedAttributesField);
    }
  }, [embedType?.embed_fields, workspaceId, editItem]);

  return (
    <Modal
      title="Credential"
      secondaryTitle={`Add`}
      isOpen={isOpen}
      onCancel={onCancel}
      onSuccess={manageIntegration}
      defaultOptions={{
        onSuccessButtonText: "Save",
        onSuccessLoaderVisible: disabled,
        onSuccessLoaderStart: disabled,
      }}>
      <div className="relative w-full space-y-4">
        {embedType?.type !== "IFRAME_EMBED" && (
          <div className="relative mb-4 flex w-full">
            <div className="relative rounded-md border border-gray-200/50 bg-white px-4 py-3 shadow-md">
              <div className="flex gap-3">
                <div className="relative z-0 h-10 w-10 flex-shrink-0">
                  <div className="absolute -right-1 -top-1 flex h-4 w-4 items-center justify-center rounded-full border border-gray-200 bg-white shadow">
                    <ShieldCheckIcon className="h-2.5 w-2.5 text-green-500" />
                  </div>
                  <div className="h-full w-full overflow-hidden rounded object-contain">
                    <div
                      className=""
                      dangerouslySetInnerHTML={{ __html: providerDetails?.image }}
                    />
                  </div>
                </div>
                <div className="w-full flex-shrink space-y-1">
                  <div className="text-base font-semibold leading-none text-gray-800">{providerDetails?.modal_title}</div>
                  <div className="text-sm text-gray-500">
                    Obtain keys at
                    <a
                      href={providerDetails?.link}
                      target="_blank"
                      rel="noreferrer"
                      className="mx-1 inline-flex items-center gap-1 text-sm text-gray-500">
                      <span className="underline">{providerDetails?.link_title}</span>
                      <span>
                        <LinkIcon className="h-4 w-4" />
                      </span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        {embedType?.embed_fields &&
          embedType?.embed_fields?.length > 0 &&
          embedType?.embed_fields?.map((field) => (
            <Fragment key={field?._id}>
              {embedType?.type === "IFRAME_EMBED" && ((providerDetails?.code === "domo" && field?.name === "client_id") || (providerDetails?.code === "metabase" && field?.name === "subscriber_instance_url")) && (
                <div className="relative mb-4 flex w-full">
                  <div className="relative rounded-md border border-gray-200/50 bg-white px-4 py-3 shadow-md">
                    <div className="flex gap-3">
                      <div className="relative z-0 h-10 w-10 flex-shrink-0">
                        <div className="absolute -right-1 -top-1 flex h-4 w-4 items-center justify-center rounded-full border border-gray-200 bg-white shadow">
                          <ShieldCheckIcon className="h-2.5 w-2.5 text-green-500" />
                        </div>
                        <div className="h-full w-full overflow-hidden rounded object-contain">
                          <div
                            className=""
                            dangerouslySetInnerHTML={{ __html: providerDetails?.image }}
                          />
                        </div>
                      </div>
                      <div className="w-full flex-shrink space-y-1">
                        <div className="text-base font-semibold leading-none text-gray-800">{providerDetails?.modal_title}</div>
                        <div className="text-sm text-gray-500">
                          Obtain keys at
                          <a
                            href={providerDetails?.link}
                            target="_blank"
                            rel="noreferrer"
                            className="mx-1 inline-flex items-center gap-1 text-sm text-gray-500">
                            <span className="underline">{providerDetails?.link_title}</span>
                            <span>
                              <LinkIcon className="h-4 w-4" />
                            </span>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {editItem
                ? editItem?.workspace_id
                  ? (field?.used_for === "both" || field?.used_for === "workspace") && (
                      <FieldItem
                        field={field}
                        editItem={editItem}
                        getValue={getValue}
                        getPlaceholder={getPlaceholder}
                        onChange={onChange}
                        onRemove={onRemove}
                      />
                    )
                  : (field?.used_for === "both" || field?.used_for === "global") && (
                      <FieldItem
                        field={field}
                        editItem={editItem}
                        getValue={getValue}
                        getPlaceholder={getPlaceholder}
                        onChange={onChange}
                        onRemove={onRemove}
                      />
                    )
                : workspaceId
                  ? (field?.used_for === "both" || field?.used_for === "workspace") && (
                      <FieldItem
                        field={field}
                        editItem={editItem}
                        getValue={getValue}
                        getPlaceholder={getPlaceholder}
                        onChange={onChange}
                        onRemove={onRemove}
                      />
                    )
                  : (field?.used_for === "both" || field?.used_for === "global") && (
                      <FieldItem
                        field={field}
                        editItem={editItem}
                        getValue={getValue}
                        getPlaceholder={getPlaceholder}
                        onChange={onChange}
                        onRemove={onRemove}
                      />
                    )}
            </Fragment>
          ))}
      </div>
    </Modal>
  );
};

const mapStateToProps = (state) => {
  return {
    workspaceDetails: state.workspaceDetails,
  };
};
export default connect(mapStateToProps, { workspaceIntegrationCredentialCreate, workspaceIntegrationCredentialManage })(WorkspacesIntegrationModal);
