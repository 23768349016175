import { apiRequest } from "src/async/apiUtils";
import { GET_WORKSPACE_DATA, REMOVE_WORKSPACE, GET_WORKSPACE_DETAILS } from "src/actions/type";
import { objectToFormData } from "src/helpers";

export const getWorkspaceData =
  (payload, signal = null) =>
  async (dispatch) => {
    try {
      const { data } = await apiRequest("post", `/workspaces/list?include=["groups","users","pages"]`, { body: payload });
      if (data.status === 200) {
        dispatch({ type: GET_WORKSPACE_DATA, payload: data.data });
        return data;
      } else {
        throw new Error(data.meta.message);
      }
    } catch (error) {
      throw new Error(error.data.meta.message);
    }
  };

export const editWorkspaceGroupDetails =
  (payload, signal = null) =>
  async (dispatch) => {
    try {
      const params = { workspace_id: payload.workspaceId };
      const { data } = await apiRequest("PUT", `/workspaces/:workspace_id/groups`, { body: payload, params }, { signal });

      if (data.status === 200) {
        return data.message;
      } else {
        throw new Error(data.message);
      }
    } catch (error) {
      throw new Error(error.message);
    }
  };

export const manageGroupUser =
  (payload, signal = null) =>
  async (dispatch) => {
    try {
      const params = { workspace_id: payload.workspace_id };
      const { data } = await apiRequest("POST", `/workspaces/:workspace_id/groups/users/manage`, { body: payload, params }, { signal });

      if (data.status === 200) {
        return data.message;
      } else {
        throw new Error(data.message);
      }
    } catch (error) {
      throw new Error(error.message);
    }
  };

export const userPermissionList =
  (payload, signal = null) =>
  async (dispatch) => {
    try {
      const params = { workspace_id: payload.workspace_id };
      const { data } = await apiRequest("GET", `/workspaces/:workspace_id/users/permissions/list`, { body: payload, params }, { signal });

      if (data.status === 200) {
        return data.data;
      } else {
        throw new Error(data.message);
      }
    } catch (error) {
      throw new Error(error.message);
    }
  };
export const userWorkspacesAndAssociatedPermissions =
  (payload, signal = null) =>
  async (dispatch) => {
    try {
      const params = { user_id: payload.user_id };
      const { data } = await apiRequest("GET", `/users/:user_id/workspaces/permissions`, { body: payload, params }, { signal });

      if (data.status === 200) {
        return data.data;
      } else {
        throw new Error(data.message);
      }
    } catch (error) {
      throw new Error(error.message);
    }
  };

export const userListOfWorkspacesAndPermissions =
  (payload, signal = null) =>
  async (dispatch) => {
    try {
      const params = { workspace_id: payload.workspace_id };
      const { data } = await apiRequest("POST", `/workspaces/:workspace_id/permission/list`, { body: payload, params }, { signal });

      if (data.status === 200) {
        return data.data;
      } else {
        throw new Error(data.message);
      }
    } catch (error) {
      throw new Error(error.message);
    }
  };

export const usersPermissionManage =
  (payload, signal = null) =>
  async (dispatch) => {
    try {
      const params = { workspace_id: payload.workspace_id };
      const { data } = await apiRequest("PUT", `workspaces/:workspace_id/users/permissions/manage`, { body: payload, params }, { signal });

      if (data.status === 200) {
        return data.message;
      } else {
        throw new Error(data.message);
      }
    } catch (error) {
      throw new Error(error.message);
    }
  };

export const userPermissionCheckboxManage =
  (payload, signal = null) =>
  async (dispatch) => {
    try {
      const params = { workspace_id: payload.workspace_id };
      const { data } = await apiRequest("POST", `/workspaces/:workspace_id/users/permissions/checkbox/manage`, { body: payload, params }, { signal });

      if (data.status === 200) {
        return data.message;
      } else {
        throw new Error(data.message);
      }
    } catch (error) {
      throw new Error(error.message);
    }
  };

export const manageWorkspaceSettings = (payload) => async (dispatch) => {
  try {
    const updatedPayload = objectToFormData(payload);
    const params = { workspace_id: payload.workspace_id };
    const { data } = await apiRequest("PUT", `/workspaces/:workspace_id/settings`, { body: updatedPayload, params }, { headers: { "Content-Type": "multipart/form-data" } });

    if (data.status === 200) {
      dispatch({ type: GET_WORKSPACE_DETAILS, payload: data.data });
      return data.message;
    } else {
      throw new Error(data.message);
    }
  } catch (error) {
    throw new Error(error.message);
  }
};

export const manageWorkspacePageEmbeddingSettings = (payload) => async (dispatch) => {
  try {
    const params = { workspace_id: payload.workspace_id };
    const { data } = await apiRequest("PUT", `/workspaces/:workspace_id/page-embedding/settings`, { body: payload, params });

    if (data.status === 200) {
      dispatch({ type: GET_WORKSPACE_DETAILS, payload: data.data });
      return data.message;
    } else {
      throw new Error(data.message);
    }
  } catch (error) {
    throw new Error(error.message);
  }
};

export const manageWorkspaceSiteName = (payload) => async (dispatch) => {
  try {
    const updatedPayload = objectToFormData(payload);
    const params = { workspace_id: payload.workspace_id };
    const { data } = await apiRequest("PUT", `/workspaces/:workspace_id/site-name`, { body: updatedPayload, params }, { headers: { "Content-Type": "multipart/form-data" } });

    if (data.status === 200) {
      dispatch({ type: GET_WORKSPACE_DETAILS, payload: data.data });
      return data.message;
    } else {
      throw new Error(data.message);
    }
  } catch (error) {
    throw new Error(error.message);
  }
};

export const getWorkspaceDetails = (payload) => async (dispatch) => {
  try {
    const params = { workspace_id: payload.workspace_id };
    const { data } = await apiRequest("GET", `/workspaces/:workspace_id`, { body: payload, params });

    if (data.status === 200) {
      dispatch({ type: GET_WORKSPACE_DETAILS, payload: data.data });
      return data.data;
    } else {
      throw new Error(data.message);
    }
  } catch (error) {
    throw new Error(error.message);
  }
};

export const getUserWorkspaceData =
  (payload, signal = null) =>
  async (dispatch) => {
    try {
      const params = { user_id: payload.user_id };
      const { data } = await apiRequest("GET", `/users/:user_id/workspaces`, { params }, { signal });
      if (data.status === 200) {
        return data.data;
      } else {
        throw new Error(data.message);
      }
    } catch (error) {
      throw new Error(error.message);
    }
  };

export const workspaceIntegrationList =
  (payload, signal = null) =>
  async (dispatch) => {
    try {
      const params = { workspace_id: payload.workspace_id };
      const { data } = await apiRequest("GET", `/workspaces/:workspace_id/integrations`, { params }, { signal });

      if (data.status === 200) {
        return data.data;
      } else {
        throw new Error(data.message);
      }
    } catch (error) {
      throw new Error(error.message);
    }
  };

export const workspaceIntegrationFilterList =
  (payload, signal = null) =>
  async (dispatch) => {
    try {
      const params = { workspace_id: payload.workspace_id };
      const { data } = await apiRequest("GET", `/workspaces/:workspace_id/filters`, { params }, { signal });

      if (data.status === 200) {
        return data.data;
      } else {
        throw new Error(data.message);
      }
    } catch (error) {
      throw new Error(error.message);
    }
  };

export const workspaceIntegrationFilterManage =
  (payload, signal = null) =>
  async (dispatch) => {
    try {
      const params = { workspace_id: payload.workspace_id };
      const { data } = await apiRequest("PUT", `/workspaces/:workspace_id/filters`, { body: payload, params }, { signal });

      if (data.status === 200) {
        return data;
      } else {
        throw new Error(data.message);
      }
    } catch (error) {
      throw new Error(error.message);
    }
  };

export const workspaceIntegrationCredentialCreate =
  (payload, signal = null) =>
  async (dispatch) => {
    try {
      let url = `/integrations/:integration_id/credentials`;
      if (payload.workspace_id) {
        url = `/workspaces/:workspace_id/integrations/:integration_id/credentials`;
      }
      const params = { workspace_id: payload.workspace_id, integration_id: payload.integration_id };
      delete payload.workspace_id;
      delete payload.integration_id;
      const { data } = await apiRequest("POST", url, { body: payload, params }, { signal });

      if (data.status === 200) {
        return data.data;
      } else {
        throw new Error(data.message);
      }
    } catch (error) {
      throw new Error(error.message);
    }
  };

export const workspaceIntegrationCredentialManage =
  (payload, signal = null) =>
  async (dispatch) => {
    try {
      let url = `/integrations/:integration_id/credentials/:credential_id`;
      if (payload.workspace_id) {
        url = `/workspaces/:workspace_id/integrations/:integration_id/credentials/:credential_id`;
      }
      const params = { workspace_id: payload.workspace_id, integration_id: payload.integration_id, credential_id: payload.credential_id };
      delete payload.workspace_id;
      delete payload.integration_id;
      delete payload.credential_id;
      const { data } = await apiRequest("PUT", url, { body: payload, params }, { signal });

      if (data.status === 200) {
        return data.data;
      } else {
        throw new Error(data.message);
      }
    } catch (error) {
      throw new Error(error.message);
    }
  };

export const workspaceIntegrationCredentialDelete =
  (payload, signal = null) =>
  async (dispatch) => {
    try {
      let url = `/integrations/:integration_id/credentials/:credential_id`;
      if (payload.workspace_id) {
        url = `/workspaces/:workspace_id/integrations/:integration_id/credentials/:credential_id`;
      }
      const params = { workspace_id: payload.workspace_id, integration_id: payload.integration_id, credential_id: payload.credential_id };
      delete payload.workspace_id;
      delete payload.integration_id;
      delete payload.credential_id;
      const { data } = await apiRequest("DELETE", url, { params }, { signal });

      if (data.status === 200) {
        return data.data;
      } else {
        throw new Error(data.message);
      }
    } catch (error) {
      throw new Error(error.message);
    }
  };

export const workspaceIntegrationCredentialMakeDefault =
  (payload, signal = null) =>
  async (dispatch) => {
    try {
      const params = { workspace_id: payload.workspace_id, integration_id: payload.integration_id, credential_id: payload.credential_id };
      const { data } = await apiRequest("POST", `/workspaces/:workspace_id/integrations/:integration_id/credentials/:credential_id/make-default`, { params }, { signal });

      if (data.status === 200) {
        return data.data;
      } else {
        throw new Error(data.message);
      }
    } catch (error) {
      throw new Error(error.message);
    }
  };

export const workspaceIntegrationCredentialManageCreatedBy =
  (payload, signal = null) =>
  async (dispatch) => {
    try {
      let url = `/integrations/:integration_id/credentials/:credential_id/set-created-by`;
      if (payload.workspace_id) {
        url = `/workspaces/:workspace_id/integrations/:integration_id/credentials/:credential_id/set-created-by`;
      }
      const params = { workspace_id: payload.workspace_id, integration_id: payload.integration_id, credential_id: payload.credential_id };
      delete payload.workspace_id;
      delete payload.integration_id;
      delete payload.credential_id;
      const { data } = await apiRequest("POST", url, { body: payload, params }, { signal });

      if (data.status === 200) {
        return data.data;
      } else {
        throw new Error(data.message);
      }
    } catch (error) {
      throw new Error(error.message);
    }
  };

export const deleteWorkspace = (id) => async (dispatch) => {
  try {
    const params = { workspace_id: id };
    const { data } = await apiRequest("DELETE", `/workspaces/:workspace_id`, { params });

    if (data.status === 200) {
      dispatch({ type: REMOVE_WORKSPACE, payload: id });
      return data;
    } else {
      throw new Error(data.message);
    }
  } catch (error) {
    throw new Error(error.message);
  }
};

export const getWorkspaceSmtpSettings = async (payload) => {
  try {
    const params = { workspace_id: payload.workspace_id };
    const { data } = await apiRequest("GET", `/workspaces/:workspace_id/smtp/settings`, { params });
    return data.data;
  } catch (error) {
    throw new Error(error.message);
  }
};

export const manageWorkspaceSmtpSettings = async (payload) => {
  try {
    const params = { workspace_id: payload.workspace_id };
    const { data } = await apiRequest("PUT", `/workspaces/:workspace_id/smtp/settings`, { body: payload, params });
    return data.message;
  } catch (error) {
    throw new Error(error.message);
  }
};
